@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800;900&family=Sen:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

img {
  max-width: 100%;
  object-fit: contain;
}
:root {
  --black: #232536;
  --green: #cef8a1;
  --backgorund-1: #f5f5f5;
  --background: #fff;
  --white: #fff;
  --red: hsl(357, 64%, 45%);
  --red-1: hsl(357, 64%, 61%);
  --red-2: hsl(357, 64%, 68%);
  --red-3: hsl(357, 64%, 76%);
  --red-4: hsl(357, 64%, 81%);
  --red-5: hsl(357, 64%, 85%);
  --red-6: hsl(357, 64%, 90%);
  --red-opacity: rgba(255, 255, 255, 0.1);
  --red-hover: hsl(357, 64%, 40%);
  --red-hover2: hsl(357, 64%, 35%);
  --red-hover3: hsl(357, 64%, 30%);
  --gray: #8391a1;
  --dark-grey: #4c4c4c;
  --medium-grey: #6d6e76;
  --medium-grey-90: #dadadc;
  --medium-gray-10: rgba(109, 110, 118, 0.1);
  --light-grey: #f4f5f7;
  --light-blue: #e7f4ff;
  --light-green: #f2fde7;
  --border: 1px solid var(--medium-grey-90);
  --border-opacity: 1px solid rgba(255, 255, 255, 0.2);
}

body {
  font-size: 16px;
  font-family: "Sen", sans-serif !important;
  background-color: var(--backgorund-1) !important;
}
a,
h1,
h2,
h3,
h4,
p,
span,
button,
h5,
h6 {
  font-family: "Sen", sans-serif !important;
}
.w-6 {
  min-width: 16px;
}

.h-6 {
  min-height: 16px;
}
.w-22 {
  min-width: 22px;
}

.h-22 {
  min-height: 22px;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-decoration: none;
  color: var(--medium-grey);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}
.description * {
  text-decoration: none;
  color: var(--medium-grey);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}

.btnwr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.text-editor .ql-toolbar {
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-color: var(--backgorund-1);
}
.text-editor .ql-container {
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  /* background-color: var(--backgorund-1); */
}
.text-editor .ql-container .ql-editor {
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  /* background-color: var(--backgorund-1); */
}
.hr {
  width: 100%;
  margin: 24px 0;
  border-bottom: var(--border);
}

.buttons-resp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 24px;
}

.editor-label {
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 8px;
  color: var(--black);
  font-size: 14px;
  font-weight: 700;
  line-height: 142%;
}
.editor-wr {
  margin-top: 8px;
}
.error-te .ql-editor.ql-blank::before {
  color: red !important;
}
.error-te .ql-editor {
  color: red !important;
}
/* td {
  min-width: 200px;
} */
